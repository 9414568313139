import React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import containerClasses from "../styles/container.module.css"

const PrivacyPage = () => (
  <Layout>
    <SEO
      title="Privacy Policy - Miss Elizabeth Hawkes"
      description="Miss Elizabeth Hawkes – Privacy Policy: Why we collect your personal data and how
       that data might be used."
    />
    <div className={`${containerClasses.container} py-30`}>
      <div className="col-span-4 md:col-span-8 lg:col-start-2 lg:col-span-10">
        <h1 className="fluid-heading-04">Privacy Policy</h1>
        <p>
          The following policy discusses why we collect your personal data and
          how that data might be used. It also explains the rights you have with
          regards to the usage of your personal data.
        </p>
        <h2 className="pt-4 fluid-heading-03">1. Introduction</h2>
        <p>
          1.1    We are committed to safeguarding the privacy of visitors to
          this website and users of our service.
        </p>
        <p>
          1.2    This policy applies where we are acting as a data controller
          with respect to the personal data of such persons; in other words,
          where we determine the purposes and means of the processing of that
          personal data.
        </p>
        <p>
          1.3    We use cookies on our website. Insofar as those cookies are not
          strictly necessary for the provision of our website and services, we
          will ask you to consent to our use of cookies when you first visit our
          website.
        </p>
        <p>
          1.4    In this policy, &quot;we&quot;, &quot;us&quot; and
          &quot;our&quot; refer to Harley Street Eyesthetics Ltd.
        </p>
        <p>
          1.5. We are registered with the Information Commissioner’s Office
          (ICO) with the following registration number: C1152658
        </p>
        <h2 className="pt-4 fluid-heading-03">2.      Credit</h2>
        <p>
          2.1    This document was created using a template from Docular (
          <Link
            to="https://seqlegal.com/free-
legal-documents/privacy-policy"
          >
            https://seqlegal.com/free-legal-documents/privacy-policy
          </Link>
          ).
        </p>
        <h2 className="pt-4 fluid-heading-03">
          3.      The personal data that we collect
        </h2>
        <p>
          3.1    In this Section 3 we have set out the general categories of
          personal data that we process.
        </p>
        <p>
          3.2    We may process data enabling us to get in touch with you
          (&quot;contact data&quot;). The contact data may include your name,
          email address, telephone number and reason for your enquiry as
          submitted via email or our contact form.  The source of the contact
          data is you.
        </p>
        <p>
          3.3    We may process information contained in or relating to any
          communication that you send to us or that we send to you
          (&quot;communication data&quot;). The communication data may include
          the communication content, which may incorporate confidential medical
          information.  By sending medical information to us, you agree to its
          processing and use for medical care, in accordance with the principles
          of GMC Good Medical Practice.
        </p>
        <p>
          3.4    We may process data about your use of our website and services
          (&quot;usage data&quot;). The usage data may include your IP address,
          geographical location, browser type and version, operating system,
          referral source, length of visit, page views and website navigation
          paths, as well as information about the timing, frequency and pattern
          of your service use. The source of the usage data is Google Analytics.
        </p>
        <p>
          3.5 In order to provide high quality healthcare, we will collect
          sensitive details about your health, both physical and mental. In
          addition, we may collect details such as your employment or
          profession. By providing us this information, you agree to its
          processing and use for medical care, in accordance with the principles
          of GMC Good Medical Practice.
        </p>
        <p>
          3.6. On some occasions, we might need to obtain information on your
          personal data from your GP, other health professionals or relatives.
          This data will be treated in accordance with the principles of GMC
          Good Medical Practice.
        </p>
        <p>
          3.7. On some occasions, we need to share your personal data with other
          medical practitioners or providers who are responsible for your
          healthcare or future healthcare. We routinely communicate and update
          your NHS GP to update them on your healthcare. You have the option to
          not allow us to do this, and this will be discussed with you.
        </p>
        <p>
          We will always seek your consent to share personal information with
          others
        </p>
        <h2 className="pt-4 fluid-heading-03">
          4.      Purposes of processing and legal bases
        </h2>
        <p>
          4.1    In Section 4, we have set out the purposes for which we may
          process personal data and the legal bases of the processing.
        </p>
        <p>
          4.2    Operations - We may process your personal data for the purposes
          of operating our website and the provision of your medical care,
          strictly within the principles of GMC Good Medical Practice.  Your
          personal information will not be disseminated or shared with any third
          party without your consent.
        </p>
        <p>
          4.3    Relationships and communications - We may process contact data,
          and/or communication data for the purposes of managing our
          relationships, communicating with you (excluding communicating for the
          purposes of direct marketing) by email, SMS, post, and/or telephone,
          providing support services and complaint handling. The legal basis for
          this processing is our legitimate interests, namely communications
          with our website visitors and service users, the maintenance of
          relationships, and the proper administration of our website, services
          and business.
        </p>
        <p>
          4.4    Direct marketing - We may process contact data for the purposes
          of creating, targeting and sending direct marketing communications by
          email, SMS and/or post and making contact by telephone for
          marketing-related purposes. The legal basis for this processing is our
          legitimate interests, namely promoting our business and communicating
          marketing messages and offers to our website visitors and service
          users.
        </p>
        <p>
          4.5    Research and analysis - We may process usage data for the
          purposes of researching and analysing the use of our website and
          services, as well as researching and analysing other interactions with
          our business. The legal basis for this processing is our legitimate
          interests, namely monitoring, supporting, improving and securing our
          website, services and business generally.
        </p>
        <p>
          4.6    Record keeping - We may process your personal data for the
          purposes of creating and maintaining our databases, back-up copies of
          our databases and our business records generally. The legal basis for
          this processing is our legitimate interests, namely ensuring that we
          have access to all the information we need to properly and efficiently
          run our business in accordance with this policy.
        </p>
        <p>
          4.7    Security - We may process your personal data for the purposes
          of security and the prevention of fraud and other criminal activity.
          The legal basis of this processing is our legitimate interests, namely
          the protection of our website, services and business, and the
          protection of others.
        </p>
        <p>
          4.8    Insurance and risk management - We may process your personal
          data where necessary for the purposes of obtaining or maintaining
          insurance coverage, managing risks and/or obtaining professional
          advice. The legal basis for this processing is our legitimate
          interests, namely the proper protection of our business against risks.
        </p>
        <p>
          4.9    Legal claims - We may process your personal data where
          necessary for the establishment, exercise or defence of legal claims,
          whether in court proceedings or in an administrative or out- of-court
          procedure. The legal basis for this processing is our legitimate
          interests, namely the protection and assertion of our legal rights,
          your legal rights and the legal rights of others.
        </p>
        <p>
          4.10  Legal compliance and vital interests - We may also process your
          personal data where such processing is necessary for compliance with a
          legal obligation to which we are subject or in order to protect your
          vital interests or the vital interests of another natural person.
        </p>
        <h2 className="pt-4 fluid-heading-03">
          5.      Providing your personal data to others
        </h2>
        <p>
          5.1    We may disclose your personal data to our insurers and/or
          professional advisers insofar as reasonably necessary for the purposes
          of obtaining or maintaining insurance coverage, managing risks,
          obtaining professional advice.
        </p>
        <p>
          5.2    We may disclose personal contact data to parties directly
          involved in your care insofar as reasonably necessary for the
          provision of safe and effective medical care.
        </p>
        <p>
          5.3    In addition to the specific disclosures of personal data set
          out in this Section 5, we may disclose your personal data where such
          disclosure is necessary for compliance with a legal obligation to
          which we are subject, or in order to protect your vital interests or
          the vital interests of another natural person. We may also disclose
          your personal data where such disclosure is necessary for the
          establishment, exercise, or defence of legal claims, whether in court
          proceedings or in an administrative or out-of-court procedure.
        </p>
        <h2 className="pt-4 fluid-heading-03">
          6.      International transfers of your personal data
        </h2>
        <p>
          6.1    In Section 6, we provide information about the circumstances in
          which your personal data may be transferred to countries outside the
          United Kingdom.
        </p>
        <p>
          6.2     The hosting facilities for our website are provided by Netlify
          and are distributed worldwide. The competent data protection
          authorities have made an &quot;adequacy decision&quot; with respect to
          the data protection laws of each of these countries. Transfers to each
          of these countries will be protected by appropriate safeguards, namely
          the use of standard data protection clauses adopted or approved by the
          competent data protection authorities.
        </p>
        <p>
          6.4    You acknowledge that personal data that you submit through our
          website or services may be available, via the internet, around the
          world. We cannot prevent the use (or misuse) of such personal data by
          others.
        </p>
        <h2 className="pt-4 fluid-heading-03">
          7.      Retaining and deleting personal data
        </h2>
        <p>
          7.1    Section 7 sets out our data retention policies and procedures,
          which are designed to help ensure that we comply with our legal
          obligations in relation to the retention and deletion of personal
          data.
        </p>
        <p>
          7.2    Personal data that we process for any purpose or purposes shall
          not be kept for longer than is necessary for that purpose or those
          purposes.
        </p>
        <p>
          7.3    We will retain your personal data as per 
          <Link to="https://transform.england.nhs.uk/information-governance/guidance/records-management-code/">
            NHS Records Management Code of Practice
          </Link>
          .
        </p>
        <p>
          7.4    Notwithstanding the other provisions of this Section 7, we may
          retain your personal data where such retention is necessary for
          compliance with a legal obligation to which we are subject, or in
          order to protect your vital interests or the vital interests of
          another natural person.
        </p>
        <h2 className="pt-4 fluid-heading-03">8.      Your rights</h2>
        <p>
          8.1    In Section 8, we have listed the rights that you have under
          data protection law.
        </p>
        <p>8.2    Your principal rights under data protection law are:</p>
        <p>
          (a)    the right to access - you can ask for copies of your personal
          data;
        </p>
        <p>
          (b)    the right to rectification - you can ask us to rectify
          inaccurate personal data and to complete incomplete personal data;
        </p>
        <p>
          (c)    the right to erasure - you can ask us to erase your personal
          data;
        </p>
        <p>
          (d)    the right to restrict processing - you can ask us to restrict
          the processing of your personal data;
        </p>
        <p>
          (e)    the right to object to processing - you can object to the
          processing of your personal data;
        </p>
        <p>
          (f)    the right to data portability - you can ask that we transfer
          your personal data to another organisation or to you;
        </p>
        <p>
          (g)    the right to complain to a supervisory authority - you can
          complain about our processing of your personal data; and
        </p>
        <p>
          (h)    the right to withdraw consent - to the extent that the legal
          basis of our processing of your personal data is consent, you can
          withdraw that consent.
        </p>
        <p>
          8.3    These rights are subject to certain limitations and exceptions.
          You can learn more about the rights of data subjects by visiting 
          <Link to="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
            https://ico.org.uk/for-organisations/guide-to-data-
            protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.
          </Link>
        </p>
        <p>
          8.4    You may exercise any of your rights in relation to your
          personal data by written notice to us, using the contact details set
          out below.
        </p>
        <h2 className="pt-4 fluid-heading-03">9.      About cookies</h2>
        <p>
          9.1    A cookie is a file containing an identifier (a string of
          letters and numbers) that is sent by a web server to a web browser and
          is stored by the browser. The identifier is then sent back to the
          server each time the browser requests a page from the server.
        </p>
        <p>
          9.2    Cookies may be either &quot;persistent&quot; cookies or
          &quot;session&quot; cookies: a persistent cookie will be stored by a
          web browser and will remain valid until its set expiry date, unless
          deleted by the user before the expiry date; a session cookie, on the
          other hand, will expire at the end of the user session, when the web
          browser is closed.
        </p>
        <p>
          9.3    Cookies may not contain any information that personally
          identifies a user, but personal data that we store about you may be
          linked to the information stored in and obtained from cookies.
        </p>
        <h2 className="pt-4 fluid-heading-03">10.    Cookies that we use</h2>
        <p>10.1  We use cookies for the following purposes:</p>
        <p>
          (a)    authentication and status - we use cookies to identify you when
          you visit our website and as you navigate our website, and to help us
          determine if you are logged into our website
        </p>
        <p>
          (b)    personalisation - we use cookies to store information about
          your preferences and to personalise our website for you
        </p>
        <p>
          (c)    analysis - we use cookies to help us to analyse the use and
          performance of our website and services
        </p>
        <p>
          (d)    cookie consent - we use cookies to store your preferences in
          relation to the use of cookies more generally
        </p>
        <h2 className="pt-4 fluid-heading-03">
          11.    Cookies used by our service providers
        </h2>
        <p>
          11.1  Our service providers use cookies and those cookies may be
          stored on your computer when you visit our website.
        </p>
        <p>
          11.2  We use Google Analytics. Google Analytics gathers information
          about the use of our website by means of cookies. The information
          gathered is used to create reports about the use of our website. You
          can find out more about Google&#39;s use of information by visiting 
          <Link to="https://www.google.com/policies/privacy/partners/">
            https://www.google.com/policies/privacy/partners/
          </Link>{" "}
          and you can review Google&#39;s privacy policy at 
          <Link to="https://policies.google.com/privacy">
            https://policies.google.com/privacy.
          </Link>
        </p>
        <h2 className="pt-4 fluid-heading-03">12.    Managing cookies</h2>
        <p>
          12.1  Most browsers allow you to refuse to accept cookies and to
          delete cookies. The methods for doing so vary from browser to browser,
          and from version to version. You can however obtain up-to-date
          information about blocking and deleting cookies via these links:
        </p>
        <p>
          (a)    
          <Link to="https://support.google.com/chrome/answer/95647">
            https://support.google.com/chrome/answer/95647
          </Link>{" "}
          (Chrome);
        </p>
        <p>
          (b)    
          <Link
            to="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-
preferences"
          >
            https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-
            preferences
          </Link>{" "}
          (Firefox);
        </p>
        <p>
          (c)    
          <Link to="https://help.opera.com/en/latest/security-and-privacy/">
            https://help.opera.com/en/latest/security-and-privacy/
          </Link>{" "}
          (Opera);
        </p>
        <p>
          (d)    
          <Link
            to="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-
manage-cookies"
          >
            https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-
            manage-cookies
          </Link>{" "}
          (Internet Explorer);
        </p>
        <p>
          (e)    
          <Link
            to="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-
sfri11471/mac"
          >
            https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-
            sfri11471/mac
          </Link>{" "}
          (Safari); and
        </p>
        <p>
          (f)    
          <Link to="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
            https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
          </Link>
           (Edge).
        </p>
        <p>
          12.2  Blocking all cookies will have a negative impact upon the
          usability of many websites.
        </p>
        <p>
          12.3  If you block cookies, you will not be able to use all the
          features on our website.
        </p>
        <h2 className="pt-4 fluid-heading-03">13.    Amendments</h2>
        <p>
          13.1  We may update this policy from time to time by publishing a new
          version on our website.
        </p>
        <p>
          13.2  You should check this page occasionally to ensure you are happy
          with any changes to this policy.
        </p>
        <h2 className="pt-4 fluid-heading-03">14.    Our details</h2>
        <p>
          14.1  This website is operated by Jess Brownless Design on behalf of
          Harley Street Eyesthetics Ltd. and owned by Harley Street Eyesthetics
          Ltd.
        </p>
        <p>
          14.2  We are registered in England and Wales and our registered office
          is: Harley Street Eyesthetics Ltd, c/o Wellington House, 273-275 High
          Street, London Colney, Hertfordshire, United Kingdom, AL2 1HA.
        </p>
        <p>14.3  You can contact us:</p>
        <p>(a)    by post, to the postal address given above;</p>
        <p>(b)    using our website contact form;</p>
        <p>
          (c)    by telephone, on the contact number published on our website;
          or
        </p>
        <p>
          (d)    by email, using the email address published on our website.
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
